<template>
	<div>
		<!-- Players will injected here -->
		<v-row no-gutters id="agora-layout-container"></v-row>

		<!-- Video call controls (show only if call has started) -->
		<v-row align="center" justify="center" v-if="alreadyJoined">
			<div id="video-player-button-group-wr" class="text-center">
				<v-btn
					fab
					dark
					large
					color="error"
					class="mx-2"
					@click="stopRecording()"
				>
					<v-icon dark>$vuetify.icons.values.stop_recording</v-icon>
				</v-btn>
			</div>
		</v-row>
	</div>
</template>

<script>
import RTCClient from "@/services/agora/rtc_client";
import { mapState } from "vuex";

export default {
	name: "AgoraRecordingPage",
	data() {
		return {
			rtc_client_obj: null,
		};
	},

	created() {
		this.rtc_client_obj = new RTCClient();
	},

	mounted() {
		this.startVideoCall();
	},

	computed: {
		...mapState({
			back_route: (state) => state.agora.back_route,
		}),
		alreadyJoined() {
			return this.rtc_client_obj.joined;
		},
	},

	watch: {
		"rtc_client_obj.new_window": function(val) {
			if (val) {
				val.onbeforeunload = () => {
					this.redirectBack();
				};
			}
		},
	},

	methods: {
		async startVideoCall() {
			try {
				this.$loader.start();

				await this.rtc_client_obj.startVideoCall();
				await this.rtc_client_obj.startCallRecording();
			} catch (error) {
				this.$announce.error(error);
				this.rtc_client_obj.closeNewWindow();
				this.redirectBack();
			} finally {
				this.$loader.stop();
			}
		},

		async stopRecording() {
			try {
				this.$loader.start();

				let response = await this.rtc_client_obj.stopCallRecording();

				// Save this response as answer to this question
				await this.submitAnswer(response);

				await this.rtc_client_obj.endVideoCall();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				await this.rtc_client_obj.closeNewWindow();
				this.redirectBack();
				this.$loader.stop();
			}
		},

		async submitAnswer(response) {
			// Submit this recording to the selected question
			if (
				response.serverResponse &&
				response.serverResponse.uploadingStatus == "uploaded"
			) {
				await this.$store.dispatch("questionnaire/submitAnswer", {
					questionnaire_id: this.$route.params.questionnaire_id,
					aws: response.serverResponse.fileList,
				});
			} else {
				throw new Error("app.ntfy.err.cannot_record", {
					cause: "werCustom",
				});
			}
		},

		redirectBack() {
			this.$router.push({ name: this.back_route });
		},
	},

	async beforeDestroy() {
		await this.rtc_client_obj.closeNewWindow();
		// Reset agora state
		this.$store.commit("agora/RESET_STATE");
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/agora.scss";
</style>
